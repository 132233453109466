import React from 'react'

function Footer() {
    return (
        <footer class="bg-[#0f072c]">
            <div class="w-full mx-auto max-w-screen-xl p-4 flex flex-col items-center justify-center md:flex-row md:justify-between">
                <span class="text-sm text-gray-500 text-center dark:text-gray-400">© 2025 <a href="https://algoart.io" class="hover:underline">ALGOARTS™ All rights reserved</a>. ALGOARTS™ is a trademark of ALGOARTS Private Limited.</span>
                <ul class="flex flex-col items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0 md:flex-row md:mt-0 md:items-center">
                    {/* <li class="mb-2 md:mb-0">
                        <a href="#" class="hover:underline me-4 md:me-6">About</a>
                    </li>
                    <li class="mb-2 md:mb-0">
                        <a href="#" class="hover:underline me-4 md:me-6">Privacy Policy</a>
                    </li>
                    <li class="mb-2 md:mb-0">
                        <a href="#" class="hover:underline me-4 md:me-6">Code of conduct</a>
                    </li>
                    <li>
                        <a href="#" class="hover:underline">Contact</a>
                    </li> */}
                </ul>
            </div>
        </footer>

    )
}

export default Footer
